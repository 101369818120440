<template>
    <div>
      <section-item height="600px" :style="{backgroundImage: 'url(' + require('@/assets/banner/service-banner.png') + ')' }"></section-item>
      <case-list></case-list>
    </div>
</template>

<script>
import CaseList from "./case-list";
export default {
  components: {CaseList}
}
</script>
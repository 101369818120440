<template>
  <section-item bgColor="#ffffff" height="1460px">
    <div class="case-item-box">
      <case-item @click="$navTo('/case-detail/product-epidemic')" :img="require('@/assets/case/case1.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-yiting')" :img="require('@/assets/case/case2.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-warning')" :img="require('@/assets/case/case3.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-taoyuan')" :img="require('@/assets/case/case4.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-anti-fraud')" :img="require('@/assets/case/case5.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-drowning')" :img="require('@/assets/case/case6.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-estimate')" :img="require('@/assets/case/case7.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-code')" :img="require('@/assets/case/case8.png')"></case-item>
    </div>
  </section-item>
</template>

<script>
import CaseItem from "../../components/case-item/case-item";
export default {
  name: "case-list",
  components: {CaseItem},
  data() {
    return {

    };
  },
  methods: {

  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.case-item-box {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-row-gap: 40px;
}
</style>

